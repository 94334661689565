$(function(){

    $("#profile-avatar-navigation-button").on("click", function(){
        var dropdown = $("#profile-dropdown-area")
        if (dropdown.hasClass("opacity-0")) {
            dropdown.addClass("transform opacity-100 scale-100")
            dropdown.removeClass("transform opacity-0 scale-95")
        }else{
            dropdown.removeClass("transform opacity-100 scale-100")
            dropdown.addClass("transform opacity-0 scale-95")
        }
    })

    $("#account_logo_trigger").on("click", function(e){
        $("#account_logo").trigger('click');
        e.preventDefault();
    });

    $("#account_banner_trigger").on("click", function(e){
        $("#account_banner").trigger('click');
        e.preventDefault();
    });

   
    $("#account_favicon_trigger").on("click", function(e){
        $("#account_favicon").trigger('click');
        e.preventDefault();
    });

    $("#user_avatar_trigger").on("click", function(e){
        $("#user_avatar").trigger('click');
        e.preventDefault();
    });

    $("#change_school_button").on("click", function(e){
        $("#schoolSearchModal").show()
    })

})
